import { ref } from "vue";
import c3api from "../c3api";
import { useToast } from "vue-toastification";

export default function useProducts() {

    const productLoadingCount = ref(0);
    const products = ref([]);
    const product = ref(null);
    const uploaderInfo = ref(null); // New reactive variable for uploader info
    const productWarehouses = ref(null);
    const inboundOrderItems = ref(null);
    const outboundOrderItems = ref(null);
    const transactionsItems = ref(null);
    const lotItems = ref(null);
    const damagedReports = ref(null);
    const productImages = ref(null);
    const inventory = ref(null);
    const uoms = ref({});

    const fetchProduct = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}`);
            return (product.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    };

    const fetchProducts = async (params = null) => {

      try {
        productLoadingCount.value++;
        const response = await c3api.get(`/products`, {params: params});
        return (products.value = response.data.data);
      } catch (error) {
        toast.error(error.response.data.data.join(". "));
      } finally {
        productLoadingCount.value--;
      }
    };

    const fetchProductWarehouses = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/warehouse_counts`);
            return (productWarehouses.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    };

    const fetchInboundActivities = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/inbound_order_items`);
            return (inboundOrderItems.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    };

    const fetchOutboundActivities = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/outbound_order_items`);
            return (outboundOrderItems.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    };

    const fetchTransactions = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/inventory_transactions`);
            return (transactionsItems.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    };

    const fetchLots = async (id, params) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/lots`, {params:params})
            return (lotItems.value = response.data.data)
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    }
    
    const fetchDamageReports = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/damage_reports`);
            return (damagedReports.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    }

    const fetchProductImages = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/product_images`);
            return (productImages.value = response.data.data)
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    }

    const fetchInventory = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/${id}/location_quantities`);
            return (inventory.value = response.data.data.inventory_data)
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    }

    const fetchUoms = async (id) => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get(`/products/uoms`);
            return (uoms.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    }

    const fetchUploaderInfo = async () => {
        try {
            productLoadingCount.value++;
            const response = await c3api.get("/products/uploader");
            uploaderInfo.value = response.data.data; // Store the response data
        } catch (error) {
            toast.error(error.response?.data?.data.join(". "));
        } finally {
            productLoadingCount.value--;
        }
    };

    return {
        products,
        fetchProducts,
        productLoadingCount,
        product,
        fetchProduct,
        productWarehouses,
        fetchProductWarehouses,
        fetchInboundActivities,
        inboundOrderItems,
        fetchOutboundActivities,
        outboundOrderItems,
        fetchTransactions,
        transactionsItems,
        fetchLots,
        lotItems,
        damagedReports,
        fetchDamageReports,
        productImages,
        fetchProductImages,
        inventory,
        fetchInventory,
        uoms,
        fetchUoms,
        fetchUploaderInfo, // Add the new function to the return object
        uploaderInfo // Add the new reactive variable to the return object
    }
}